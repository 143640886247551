/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  width: 100% !important;
  border-radius: 20px;
}

.costing-calculator .ui.selection.dropdown {
  border-radius: 0 80px 80px 0 !important;
  min-width: 46% !important;
  min-height: 40px !important;
}

.ui.selection.dropdown .menu {
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.ui.multiple.dropdown > input {
  height: 19px !important;
}

.ui.selection.dropdown {
  border-top-left-radius: @border-radius !important;
  border-top-right-radius: @border-radius !important;
}

.ui.selection.dropdown[aria-expanded=false] {
  border-bottom-left-radius: @border-radius !important;
  border-bottom-right-radius: @border-radius !important;
}

.costing-calculator .ui.selection.dropdown[aria-expanded=false] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 80px !important;
}